<template>
	<div :class="show && 'show'" class="fullscreen-nav-container">
		<div class="top-section">
			<div class="menu-close" @click="$emit('hide-nav')">
				<font-awesome-icon :icon="`fal fa-xmark`" size="1x" />
				<span>{{ $t('close') }}</span>
			</div>
			<divider />
			<lang-nav @hide-nav="$emit('hide-nav')" />
		</div>
		<nav class="nav-wrapper">
			<main-nav-list :items="topmenu[locale]" @hide-nav="$emit('hide-nav')" />
			<div class="vertical-divider nav-divider" />
			<locations-nav-list :key="route.fullPath" @hide-nav="$emit('hide-nav')" />
		</nav>
	</div>
</template>

<script setup>
const route = useRoute();
const { locale } = useI18n();

defineProps({
	show: { type: Boolean, default: false },
});

defineEmits(['hide-nav']);

const { data: topmenu } = await useWebsiteFetch('topmenu');
</script>

<style lang="scss" scoped>
.fullscreen-nav-container {
	z-index: 1003;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: all 0.4s ease-in-out;
	transform: translate(0, 100%) matrix(1, 0, 0, 1, 0, 0);
	background: var(--primary-background-color);
	position: fixed;
	top: 0;
	left: 0;
	padding: 20px 30px;

	.vertical-divider {
		background: #3c3834;
		margin: 0 calc(8% - 1px) 0 0;
		width: 1px;
		height: 100%;
		min-height: 70vh;
	}

	.nav-wrapper {
		display: flex;
		flex-flow: row nowrap;
		align-items: flex-start;
		height: calc(100% - 76px);
	}

	.menu-close {
		cursor: pointer;
		padding: 0;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		margin: 0 30px 0 0;
		text-transform: uppercase;
		font-weight: 900;

		svg {
			font-size: 26px;
			margin: 0 15px 0 0;
		}

		div {
			margin: 0 15px 0 0;
		}
	}
}

.fullscreen-nav-container.show {
	transform: matrix(1, 0, 0, 1, 0, 0);
	opacity: 1;
}

.top-section {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	padding: 0 0 15px;
}

@media (max-width: 1200px) {
	.fullscreen-nav-container {
		padding: 40px 30px;

		.vertical-divider {
			display: none;
		}

		.nav-wrapper {
			flex-flow: row wrap;
		}
	}
}
</style>
