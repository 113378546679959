<template>
	<div class="locations-nav-list" :class="{ 'show-location-nav': showLocationsNav }">
		<div class="mobile-nav-back" />
		<div v-for="{ type, cities } in navList" :key="type" class="locationtype-group">
			<h3>{{ $t(type.toLowerCase()) }}</h3>
			<div class="group-wrapper">
				<div v-for="{ city, locations } in cities" :key="city.name" class="city-group">
					<h5 v-if="locations.length">{{ city }}</h5>
					<div class="location-group">
						<div v-for="location in locations" :key="location.ID">
							<a :href="location.url" target="_blank" rel="noopener noreferrer">
								<picture>
									<source :srcset="location.logoWebp" type="image/webp" />
									<source :srcset="location.logo" />
									<img
										:src="location.logo"
										:alt="location.logoAlt"
										class="location-logo"
										loading="lazy"
									/>
								</picture>
								<span>{{ location.header }}</span>
							</a>
						</div>
					</div>
				</div>
			</div>
			<nuxt-link class="view-all" :to="localePath(type.toLowerCase())">
				<div @click="$emit('hide-nav')">
					<span>{{ $t('viewAll') }} {{ $t(type.toLowerCase()) }}</span>
					<font-awesome-icon :icon="`fal fa-chevron-right`" size="1x" />
				</div>
			</nuxt-link>
		</div>
	</div>
</template>

<script setup>
const { locale } = useI18n();

const showLocationsNav = ref(false);

defineEmits(['hide-nav']);

const { data: locationsData } = await useWebsiteFetch('locations', {
	query: { language: locale.value },
	key: `${locale.value}/locations`,
});

const locationTypes = [...new Set(locationsData.value.map(({ type }) => type))];
const locationCities = [...new Set(locationsData.value.map(({ city }) => city))];
const navList = locationTypes.map((type) => ({
	type,
	cities: locationCities.map((city) => ({
		city,
		locations: locationsData.value.filter((location) => location.type === type && location.city === city),
	})),
}));
</script>

<style lang="scss" scoped>
.locations-nav-list {
	width: 65%;
	height: 100%;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: stretch;

	a {
		color: var(--body-color);
		text-decoration: none;
		margin: 0;
		padding: 3px 0;
		font-weight: 500;
		font-size: 16px;
		transition: all 0.3s ease-in-out;
		border-bottom: 2px solid rgba(0 0 0 / 0%);
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: flex-start;

		&::after {
			display: none;
		}

		img {
			margin: 0 12px 0 0;
			width: 34px;
			height: 34px;
		}

		span {
			margin: 3px 0 0;
			padding: 0 0 7px;
			position: relative;

			&::after {
				display: block;
				content: '';
				width: 100%;
				height: 1px;
				background: #3c3834;
				opacity: 0;
				transition: all 0.3s ease-in-out;
				position: absolute;
				bottom: 0;
				left: 0;
			}
		}

		&.active,
		&:hover {
			color: var(--body-color);

			span::after {
				opacity: 1;
			}
		}
	}

	.view-all {
		text-transform: uppercase;
		display: flex;
		align-items: center;
		gap: 10px;
		margin: 40px 0 0;
		font-weight: 800;
		position: absolute;
		left: 0;
		bottom: 10px;
		font-size: 14px;

		svg {
			margin: 1px 0 -1px 10px;
			font-size: 18px;
		}
	}
}

.locationtype-group {
	min-width: 28%;
	position: relative;

	&:last-child {
		.view-all {
			display: none;
		}
	}
}

.group-wrapper {
	width: 100%;
	height: 560px;
	max-height: calc(100% - 160px);
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	align-items: stretch;
}

h3 {
	text-transform: uppercase;
	font-weight: 800;
	font-size: 18px;
	line-height: 20px;
	padding: 15px 0;
	margin: 0 0 5px;
	color: var(--body-color);
}

h5 {
	&::after {
		content: '';
		display: block;
		width: 100%;
		height: 1px;
		background: #3c3834;
		margin: 10px 0 12px;
	}
}

.mobile-nav-back {
	display: none;
	position: absolute;
	top: 40px;
	left: 20px;
	background: url('~/assets/images/icon-arrow-left.png') no-repeat center right;
	background-size: 32px;
	width: 32px;
	height: 32px;
}

@media (max-width: 1500px) {
	.group-wrapper {
		height: 520px;
	}

	.locations-nav-list {
		width: 70%;

		h3 {
			padding-bottom: 10px;
		}

		a {
			padding: 0;
			margin: 0;

			span {
				padding-bottom: 5px;
			}

			img {
				width: 25px;
				height: 25px;
			}
		}
	}
}

@media (max-width: 1200px) {
	.locations-nav-list {
		display: none;
	}
}

@media (max-width: 880px) {
	.mobile-nav-back {
		display: block;
		cursor: pointer;
	}

	.locations-nav-list {
		width: 300vw;
		position: absolute;
		left: 100vw;
		bottom: 0;
		top: 0;
		background: var(--primary-background-color);
		padding: 40px 30px;

		&.show-location-nav {
			left: 0;
		}

		.locationtype-group {
			width: calc(100vw - 60px);
			left: 100vw;
			bottom: 0;
			top: 100px;
			position: absolute;

			&.active {
				left: 30px;
				right: 30px;
			}
		}
	}
}
</style>
