<template>
	<div class="main-nav-list">
		<ul>
			<li class="nav-title">{{ defaults[locale].website.hotelName }}</li>
			<li v-for="item in items" :key="item.filename" class="nav-item">
				<span @click="$emit('hide-nav')">
					<nuxt-link :to="item.filename">
						<span>{{ item.header }}</span>
					</nuxt-link>
				</span>
				<submenu v-if="item.submenu.length" :subitems="item.submenu" @hide-mobile-nav="$emit('hide-nav')" />
			</li>
		</ul>
		<locations-nav-list-mobile @hide-nav="$emit('hide-nav')" />
		<div class="nav-footer">
			<div>
				<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`" class="phone" />
				<a :href="`mailto:${defaults[locale].website.email}`" class="email" />
			</div>
			<divider />
			<social-media :socials="socials" />
		</div>
	</div>
</template>

<script setup>
const { defaults, socials, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchDefaults();

defineProps({
	items: { type: Array, default: () => [] },
});

defineEmits(['hide-nav']);
</script>

<style lang="scss" scoped>
@media (min-width: 1200px) {
	.nav-item:hover :deep(.submenu ul) {
		display: block;
	}
}

.main-nav-list {
	height: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	align-items: flex-start;
	padding: 0 50px 0 0;

	.nav-title {
		text-transform: uppercase;
		font-weight: 800;
		font-size: 20px;
		line-height: 20px;
	}

	ul {
		li {
			list-style: none;
			padding: 15px 0;
			width: 100%;
		}
	}

	.nav-footer {
		width: 100%;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;

		> div {
			display: flex;
			align-items: center;
		}

		a {
			display: flex;
			align-items: center;
			place-content: center center;
			width: 30px;
			height: 30px;
			margin: 10px 15px 10px 0;
			text-decoration: none;
			opacity: 0.75;
			background: url('~/assets/images/socials/phone.png') no-repeat center center;
			background-size: 30px;

			&::after {
				display: none;
			}

			&.email {
				background: url('~/assets/images/socials/email.png') no-repeat center center;
				background-size: 30px;
			}

			&:hover {
				opacity: 1;
			}
		}
	}
}

a {
	color: var(--body-color);
	text-decoration: none;
	margin: 0;
	padding: 5px 0;
	font-weight: 500;
	font-size: var(--body-font-size-big);
	transition: all 0.3s ease-in-out;

	span {
		padding: 0 0 7px;
		position: relative;
	}

	&.active,
	&:hover {
		color: var(--body-color);

		span::after {
			opacity: 1;
		}
	}
}

@media (max-width: 1280px) {
	.main-nav-list {
		padding-right: 5px;
	}
}

@media (max-width: 1200px) {
	.main-nav-list {
		width: 100%;

		ul li {
			padding: 5px 0;
		}

		.nav-title {
			display: none;
		}
	}

	a {
		font-size: 18px;
	}

	.nav-wrapper .nav-footer {
		position: absolute;
		bottom: 20px;
		left: 30px;
		right: 20px;
	}
}

@media (max-height: 680px) {
	.nav-wrapper .nav-footer {
		display: none;
	}
}
</style>
