<template>
	<div class="footers">
		<footer class="main-footer">
			<div class="row">
				<div class="columns column3">
					<h4>{{ defaults[locale].website.hotelName }}</h4>
					<p class="address">
						<span>{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}</span>
						<span>{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}</span>
						<span>{{ defaults[locale].website.country }}</span>
					</p>
					<social-media :socials="socials" />
				</div>
				<div class="columns column3">
					<h4>{{ $t('quickLinks') }}</h4>
					<p>
						<nuxt-link v-for="item in footermenu[locale]" :key="item.filename" :to="item.filename">
							{{ item.header }}
						</nuxt-link>
					</p>
				</div>
				<div class="columns column4">
					<h4>{{ $t('reservationOffice') }}</h4>
					<p>
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<span>{{ defaults[locale].website.phone }}</span>
						</a>
						<a :href="`mailto:${defaults[locale].website.email}`">
							<span>{{ defaults[locale].website.email }}</span>
						</a>
					</p>
				</div>
				<div class="columns column2">
					<h4>{{ $t('legal') }}</h4>
					<p>
						<nuxt-link v-for="item in footermenuLegal[locale]" :key="item.filename" :to="item.filename">
							{{ item.header }}
						</nuxt-link>
					</p>
				</div>
			</div>
		</footer>
	</div>
</template>

<script setup>
const { data: footer } = await useWebsiteFetch('footermenu');
const { footermenu, footermenuLegal } = footer.value;
const { locale } = useI18n();

const { defaults, socials, fetchDefaults } = useDefaults();

await fetchDefaults();
</script>

<style lang="scss" scoped>
.footers {
	color: var(--footer-color);
	background: var(--footer-background-color);

	.row {
		max-width: 1400px;
	}

	a,
	span {
		color: var(--footer-color);
		text-decoration: none;
	}

	h3,
	h4 {
		color: var(--footer-color);
		margin: 0 0 10px;
	}

	h4 {
		font-family: var(--body-font-family);
		font-size: 1.3em;
	}
}

.main-footer {
	padding: 100px 0 50px;

	p {
		display: flex;
		flex-flow: column wrap;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.address {
		margin-bottom: 0;
	}

	.row {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: flex-start;
	}

	.columns {
		margin: 25px 0;
	}

	a {
		display: inline-block;
	}

	span {
		display: inline-block;
	}
}

@media (max-width: 1080px) {
	.main-footer {
		.row {
			flex-flow: row wrap;
		}

		.columns {
			margin: 0 auto 40px;
			width: 50%;
			padding: 0 40px;
		}
	}
}

@media (max-width: 580px) {
	.main-footer {
		text-align: left;

		.columns {
			width: 100%;
		}
	}
}
</style>
